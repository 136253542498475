import packageJson from '../../package.json';
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import darkmode from './reducers/darkmode';
import dashboard from './reducers/dashboard';
import auth from './reducers/auth';

const darkmodePersistConfig = {
    key: 'darkmode',
    version: packageJson.version,
    storage
};
const dashboardPersistConfig = {
    key: 'dashboard',
    version: packageJson.version,
    storage
};

const authPersistConfig = {
    key: 'auth',
    version: packageJson.version,
    storage
};

const rootReducer = combineReducers({
    darkmode: persistReducer(darkmodePersistConfig, darkmode),
    dashboard: persistReducer(dashboardPersistConfig, dashboard),
    auth: persistReducer(authPersistConfig, auth)
});

const store = createStore(rootReducer);
const persistor = persistStore(store);

export { store, persistor };
