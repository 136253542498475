import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import Routes from './routes/Routes';
import { useClearCache } from 'react-clear-cache';

function App() {
    const { isLatestVersion, emptyCacheStorage } = useClearCache();

    if (!isLatestVersion) {
        emptyCacheStorage();
        console.log('Atualização do cache realizada com sucesso!');
    }

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Routes />
            </PersistGate>
        </Provider>
    );
}

export default App;
