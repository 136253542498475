const intitialState = {
    callbackList: [],
    openTickets: [],
    agenteList: [],
    ramais: [],
    filtro: [],
    areasComEspecialidades: [],
    tiposAusencia: []
};

const dashboard = function (state = intitialState, action) {
    switch (action.type) {
    case 'SET_CALLBACK_LIST':
        return { ...state, callbackList: action.payload };
    case 'SET_OPEN_TICKETS':
        return { ...state, openTickets: action.payload };
    case 'SET_AGENTE_LIST':
        return { ...state, agenteList: action.payload };
    case 'SET_AGENTE_ITEM':
        return {
            // Retorna os estado atual
            ...state,
            agenteList: state.agenteList.map(agente => {
                // Loop through the array to find the post you want to modify
                if (
                    agente.ramal === action.payload.ramal &&
                        agente.id_fila === action.payload.id_fila
                ) {
                    // Retorna o agente modificado
                    return { ...agente, ...action.payload };
                } else {
                    // Copy the post state and then modify it. Else return the same object.
                    return agente;
                }
            })
        };
    case 'SET_RAMAIS':
        return { ...state, ramais: action.payload };
    case 'SET_RAMAL_ITEM':
        return {
            // Retorna os estado atual
            ...state,
            ramais: state.ramais.map(ramal => {
                // Loop through the array to find the post you want to modify
                if (
                    ramal.ramal === action.payload.ramal &&
                        ramal.id_fila === action.payload.id_fila
                ) {
                    // Retorna o agente modificado
                    return { ...ramal, ...action.payload };
                } else {
                    // Copy the post state and then modify it. Else return the same object.
                    return ramal;
                }
            })
        };
    case 'SET_FILTRO':
        return { ...state, filtro: action.payload };
    case 'SET_AREAS_COM_ESPECIALIDADES':
        return { ...state, areasComEspecialidades: action.payload };
    case 'SET_TIPOS_AUSENCIA':
        return { ...state, tiposAusencia: action.payload };
    default:
        return state;
    }
};

export default dashboard;
