const intitialState = {
    darkMode: false
};

const darkmode = function (state = intitialState, action) {
    switch (action.type) {
    case 'SET_DARK_MODE':
        return { ...state, darkMode: action.payload };
    default:
        return state;
    }
};

export default darkmode;
