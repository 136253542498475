import React from 'react';
import ReactDOM from 'react-dom';
import './output.css';
import App from './App';

import { register as registerServiceWoker } from './serviceWorkerRegistration';

ReactDOM.render(<App />, document.getElementById('root'));

registerServiceWoker();
