import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import MoonLoader from 'react-spinners/MoonLoader';
import PrivateRoute from './PrivateRoute';

const Reclamacao = lazy(() => import('../pages/registro'));
const NotFound = lazy(() => import('../pages/404'));
const Login = lazy(() => import('../pages/login'));

const Routes = function () {
    return (
        <BrowserRouter>
            <Suspense
                fallback={
                    <div className="flex min-h-screen items-center justify-center bg-gray-50 py-12 px-4 dark:bg-gray-800 sm:px-6 lg:px-8">
                        <MoonLoader color="#202A37" loading size={60} />
                    </div>
                }
            >
                <Switch>
                    <PrivateRoute path="/registro" exact component={Reclamacao} />
                    <Route path="/login" exact component={Login}/>
                    <Route path="/" exact>{() => <Redirect to="/login"/>}</Route>
                    <Route path="*" exact={true} component={NotFound} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
};

export default Routes;
